import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Checkup from "../../components/checkup"
import { Link } from "gatsby"
import pakmaya from "../../assets/img/pakmaya-basari-hikayesi.jpg"
import odul from "../../assets/img/altin-pakmaya-odul.png"
import pakmayaKurumsal from "../../assets/img/pakmaya-kurumsal.png"
import pakmayaLansman from "../../assets/img/pakmaya-lansman.png"
import pakmayaOtrafik from "../../assets/img/pakmaya-organik-trafik.png"
import pakmayaSites from "../../assets/img/pakmaya-sites.png"
import pakmayaSeo from "../../assets/img/pakmaya-seo.png"
import pakmayaDijitalForm from "../../assets/img/pakmaya-dijital-iletisim-formu.png"
import profesyonellereOzel from "../../assets/img/pakmaya-profesyonellere-ozel.png"
import pakmayaGidaGuvenligi from "../../assets/img/pakmaya-gida-guvenligi.png"
import pakmayaGidaSertifikasi from "../../assets/img/pakmaya-gida-sertifikasi.png"
import pakmayaGlobalPazarlama from "../../assets/img/pakmaya-global-pazarlama.png"
import OtherCaseStudy from "../../components/other-case"
import logo from "../../assets/img/pakmaya-logo.png"
import myildirim from "../../assets/img/teams/22.jpg"
import sedef from "../../assets/img/teams/19.jpg"
import serife from "../../assets/img/teams/20.jpg"
import onay from "../../assets/img/teams/23.jpg"
import gizem from "../../assets/img/teams/24.jpg"
import sultan from "../../assets/img/teams/28.jpg"
import edalabasmaz from "../../assets/img/teams/29.jpg"
import bengu from "../../assets/img/teams/17.jpg"



const ReferancesDetail = () => (
    <Layout>
        <SEO title="Pakmaya Success Story" 
        description="You can check out the success story of Pakmaya, one of Turkey's largest food companies, by visiting our page!"

        />
        <div className="referances-detail py60 container">
            <div className="col-md-6 detail-left">
                <b>SUCCESS STORY</b>
                <h1>Pakmaya</h1>
                <p style={{ maxWidth: 400 }}>Pakmaya's foundations were laid in 1923 with a pharmaceutical laboratory owned by Professor Mustafa Nevzat Bey. The group decided to enter the food sector in 1973 and established its first yeast factory in Izmit.</p>
                <p style={{ maxWidth: 400, fontSize: 15 }}>In the following years, Pakmaya opened other factories in Turkey, Romania and the USA. With its exports to 130 countries, Pakmaya is one of the top three companies in the world.</p>
                <img className="pakmaya-headimg" alt="pakmaya mayalı kek harcı paketi" style={{ position: 'absolute', height: 360, right: -90, top: 0, zIndex: -1 }} src={pakmaya} />
            </div>
            <div className="col-md-6 referances-boxs">
                <div className="boxs">
                    <div className="box">
                        <span>10x</span>
                        <p>Organic traffic increase of.</p>
                    </div>
                    <div className="box">
                        <span>+%126</span>
                        <p>Reducing the cost of advertising.</p>
                    </div>

                </div>
                <div className="provided">
                    <h3>Services Provided</h3>
                    <div className="provided-box">
                        <div className="box">
                            <Link to="/email-marketing/"></Link>
                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 86 86">
                                <g id="Icons-_-Communication-_-Mail-heart" data-name="Icons-/-Communication-/-Mail-heart" transform="translate(0.169 -0.307)">
                                    <rect id="bound" width="86" height="86" transform="translate(-0.169 0.307)" fill="none" />
                                    <path id="Combined-Shape" d="M8.569,2H51.4a3.569,3.569,0,0,1,3.569,3.569V41.262A3.569,3.569,0,0,1,51.4,44.831H8.569A3.569,3.569,0,0,1,5,41.262V5.569A3.569,3.569,0,0,1,8.569,2Zm27.84,7.138c-2.3,0-4.985,2.605-6.425,4.283-1.439-1.678-4.127-4.283-6.425-4.283-4.068,0-6.425,3.173-6.425,7.21q0,6.71,12.849,14.2,12.849-7.5,12.849-13.92C42.834,12.6,40.477,9.138,36.409,9.138Z" transform="translate(13.236 5.138)" fill="#111" fill-rule="evenodd" opacity="0.3" />
                                    <path id="Combined-Shape-2" data-name="Combined-Shape" d="M5.829,6.816,35.123,27.972,64.417,6.816a1.785,1.785,0,0,1,2.829,1.447V44.034a7.138,7.138,0,0,1-7.138,7.138H10.138A7.138,7.138,0,0,1,3,44.034V8.263A1.785,1.785,0,0,1,5.829,6.816Z" transform="translate(8.097 16.643)" fill="#111" fill-rule="evenodd" />
                                </g>
                            </svg>
                            Email Marketing
                        </div>
                        <div className="box">
                            <Link to="/ui-ux-design/"></Link>
                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 76 76">
                                <g id="Icons-_-Design-_-Sketch" data-name="Icons-/-Design-/-Sketch" transform="translate(0.015 -0.307)">
                                    <rect id="bound" width="76" height="76" transform="translate(-0.015 0.307)" fill="none" />
                                    <path id="Path-48" d="M13.728,3H58.276L71,18.91H1Z" transform="translate(2.182 6.546)" fill-rule="evenodd" opacity="0.3" />
                                    <path id="Path-48-Copy" d="M71,8,36,46.184,1,8Z" transform="translate(2.182 17.456)" fill-rule="evenodd" />
                                </g>
                            </svg>
                            UI&UX Design
                        </div>
                    </div>
                </div>
                {/* <div className="project-details">
                    <h3>Künye</h3>
                    <div className="project-details_list">
                        <ul>
                            <li>Marka Yöneticisi:</li>
                            <li>Dijital Pazarlama Stratejist:</li>
                            <li>Google Ads:</li>
                            <li>SEO:</li>
                            <li>Website Geliştirme:</li>
                        </ul>
                        <ul>
                            <li><img src={simay} />Simay Akkurt</li>
                            <li><img src={eelbeyglu} />Emre Elbeyoğlu</li>
                            <li><img src={serife} />Şerife Aytekin</li>
                            <li><img src={tugce} />Tuğçe Gör</li>
                            <li><img src={onay} />Barış Onay</li>
                        </ul>
                    </div>
                </div> */}
            </div>
        </div>
        <div className="container">
            <div className="testimonial-customer" id="one">
                <p className="pakmaya-center_text" style={{ fontSize: 24, width: '70%', margin: '0 auto' }}>Besides yeast production, which is the product that gives the company its name (maya=yeats), Pakmaya shows its quality and innovative stance in bakery, pastry, milling and home consumption products. Today, Pakmaya is one of the most reliable and still rising food brands of our country.</p>
                {/* <div className="author">
                    <div>
                        <img src={customer}></img>
                    </div>
                    <div className="author-name">
                        Emre Altan<br />
                        <span>CEO</span>
                    </div>
                </div> */}
            </div>


            <div className="col-md-12 pakmaya-special py60">
                <div className="col-md-6 pakmaya-special_left">
                    <div>
                        <p>Success Story</p>
                        <img src={logo} alt="pakmaya firma logosu" />
                        <h2>Brands Worked</h2>
                        <ul>
                            <li>Pakmaya Corporate</li>
                            <li>Pakmaya Star of the Kitchen</li>
                            <li>Pakmaya World of Professionals</li>
                            <li>Professionals Academy</li>
                            <li>Pakmaya Global Marketing</li>
                        </ul>
                    </div>
                </div>
                <div className="col-md-6 pakmaya-special_right project-details">
                    <p>Pakmaya Corporate Impressum</p>
                    <div className="project-details_list">
                        <ul className="col-md-6" id="headList">
                            <li>Brand Manager</li>
                            <li>Social Media Consultant</li>
                            <li>Social Media Consultant</li>
                            <li>UI / UX Design</li>
                            <li>Content Marketing</li>
                            <li>SEO</li>
                            <li>SM Advertising Management</li>
                            <li>Google Ads & Linkedin Ads</li>
                            <li>Visual design</li>
                        </ul>
                        <ul className="col-md-6">
                            <li><img src={myildirim} alt="Murthan Yıldırım" />Murathan Yıldırım</li>
                            <li><img src={serife} alt="Şerife Aytekin" />Şerife Aytekin</li>
                            <li><img src={sedef} alt="Sedef Karaman" />Sedef Karaman</li>
                            <li><img src={onay} alt="Barış Onay" />Barış Onay</li>
                            <li><img src={bengu} alt="Bengü Dinçer" />Bengü Dinçer</li>
                            <li><img src={edalabasmaz} alt="Emel Dalabasmaz" />Emel Dalabasmaz</li>
                            <li><img src={gizem} alt="Gizem Altun Bostancı" />Gizem Altun Bostancı</li>
                            <li><img src={serife} alt="Şerife Aytekin" />Şerife Aytekin</li>
                            <li><img src={sultan} alt="Sultan Dedeoğlu"/>Sultan Dedeoğlu</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div className="fullwidth-image black referances-detail">
            <div style={{ display: 'flex', alignItems: 'center' }} className="container">
                <div className="col-md-6"><img src={odul} alt="" /></div>
                <div className="col-md-6">
                    <div className="content">
                        <p className="small">Success Story</p>
                        <h2>Awarded Social <br/> Media Management</h2>
                        <p>Turkey's biggest social media platform awards <span>@socialmediaawardsturkey</span> awarded our social media strategy for Pakmaya with a gold medal at Basic Food Category.</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="container">
            <div className="referances-detail">
                <div className="content-section content row py60" id="pkmyKrmsl">
                    <div className="col-md-6">
                        <span className="small">Success Story</span>
                        <h3>Pakmaya Corporate</h3>
                        <p>PakmaWe have been providing digital marketing services to Pakmaya since 2017 in order to transfer the corporate image of the brand. We transformed the brand perception created a positive image in the eyes of their target audience. As a requirement of brand strategy, digital is in the first place of all marketing channels.</p>
                        <p className="small">
                        We have increased the organic traffic 4 times greater compared to the day we started working. In addition, we also work on the planning, designing, implementation, and launch of their website.
                    </p>
                    </div>
                    <div className="col-md-6">
                        <img src={pakmayaKurumsal} />
                    </div>
                </div>
                <div className="content-section content row">
                    <div className="col-md-6">
                        <img style={{ paddingRight: 50 }} src={pakmayaLansman} alt="" />
                    </div>
                    <div className="col-md-6">
                        <span className="small">Success Story</span>
                        <h3>Landing Page Development Specific to the Launching Periods</h3>
                        <p>Landing page developments were carried out to better promote products to the target audience during the launch periods of the brand. Landing pages feature content of the product, product introduction videos, recipes using the product and a list of innovative recipes made with the product.</p>
                        <p className="small">
                        Users were directed to landing pages during launch periods, and a total of over 1 million page views were received. In this way, a more effective product promotion was achieved in digital during the advertising periods.
                    </p>
                        <a className="svgLink" target="_blank" href="https://pakmaya.com.tr/cikolatalar/"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 48 48">
                            <g id="Group_11" data-name="Group 11" transform="translate(-432 -2296)">
                                <g id="Ellipse_1" data-name="Ellipse 1" transform="translate(432 2296)" fill="none" stroke="#000" stroke-width="3">
                                    <circle cx="24" cy="24" r="24" stroke="none" />
                                    <circle cx="24" cy="24" r="22.5" fill="none" />
                                </g>
                                <path id="Path_80" data-name="Path 80" d="M422.384,556.893l-2.14,2.154,7.736,7.846-7.736,7.846,2.14,2.154,9.86-10Z" transform="translate(30.756 1753.107)" />
                            </g>
                        </svg>
                            <span>pakmaya.com.tr/cikolatalar/</span></a>
                    </div>
                </div>
            </div>

            <div style={{ padding: '100px 0px' }} className="container">
                <img style={{ width: "100%" }} src={pakmayaSites} />
            </div>

            <div className="text-center content">
                <span className="small">Success Story</span>
                <h2 style={{ marginTop: 7 }}>Pakmaya's Star of the Kitchen</h2>
                <p style={{ maxWidth: 680, margin: '10px auto 0' }}>We carry out the communication of Pakmaya in B2C channels in the domestic market through the Star of the Kitchen. The basis of our business is to evaluate the insights of the users and to produce and implement a responsive digital marketing strategy accordingly.</p>
            </div>

            <div className="content-section content row py60" id="orgnkTrfk">
                <div className="col-md-6">
                    <span className="small">Success Story</span>
                    <h3>10 times growth in <br/>organic traffic within 2 years</h3>
                    <p style={{ maxWidth: 415 }}>With the new SEO-compatible website prepared at the end of 2018, alongside its SEO-focused content marketing strategy; the web traffic of Pakmaya started to increase over 10 times compared to 2017.</p>
                </div>
                <div className="col-md-6">
                    <img src={pakmayaOtrafik} />
                </div>
            </div>

            <div className="text-center content">
                <span className="small">Success Story</span>
                <h2 style={{ marginTop: 7 }}>Featured Pages on Google with <br/>On-page Seo Optimization</h2>
                <p style={{ maxWidth: 515, margin: '10px auto 0' }}>The sections and recipes available on the site have increased visibility on Google with the correct on-page SEO tactics. </p>
                <p style={{ maxWidth: 680, margin: '15px auto 0' }} className="small">The Star of the Cuisine website was saturated with content compared to any other recipe site but had a very low Google visibility. Thanks to our campaign, this site received traffic from 2,000 different word groups in 2017, while it reached potential traffic from 35,000 different word groups in 2019.</p>
            </div>

            <div className="content-section content row py60">
                <div className="col-md-6">
                    <img src={pakmayaSeo} />
                </div>
                <div className="col-md-6 pl100">
                    <span className="small">Success Story</span>
                    <h3>SEO Oriented<br/> Content Marketing Strategy </h3>
                    <p style={{ maxWidth: 415 }}>Apart from the creation of SEO Content, which is prepared by targeting the keywords that will direct the brand and its products and increase its visibility; listicle contents are created in line with the target audience dynamics and popular content on social media. These “lists of flavor” allowed for fast consumption content in social media.</p>
                </div>
            </div>

            <div className="text-center content">
                <span className="small">Success Story</span>
                <h2 style={{ marginTop: 7 }}>Pakmaya World of Professionals</h2>
                <p style={{ maxWidth: 705, margin: '10px auto 0' }}>We launched the Pakmaya World of Professionals website in 2018 in order to bring Pakmaya's industrial consumption products to the right target audience. This launch was part of the strategy to position Pakmaya as the “digital meeting point” platform of the target audience, which consisted of pastry and bakery masters.</p>
                <a className="svgLink" style={{ marginTop: 20, justifyContent: 'center' }} target="_blank" href="https://pakmayaprofesyonellerdunyasi.com"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 48 48">
                    <g id="Group_11" data-name="Group 11" transform="translate(-432 -2296)">
                        <g id="Ellipse_1" data-name="Ellipse 1" transform="translate(432 2296)" fill="none" stroke="#000" stroke-width="3">
                            <circle cx="24" cy="24" r="24" stroke="none" />
                            <circle cx="24" cy="24" r="22.5" fill="none" />
                        </g>
                        <path id="Path_80" data-name="Path 80" d="M422.384,556.893l-2.14,2.154,7.736,7.846-7.736,7.846,2.14,2.154,9.86-10Z" transform="translate(30.756 1753.107)" />
                    </g>
                </svg>
                    <span>pakmayaprofesyonellerdunyasi.com</span></a>
            </div>

            <div className="content-section content row py60" id="frnPsta">
                <div className="col-md-6">
                    <span className="small">Success Story</span>
                    <h3>A Digital Communication Platform for Bakery and <br/>Pastry Masters</h3>
                    <p style={{ maxWidth: 430 }}>Pakmaya World of Professionals meets the information and communication needs of the sector with an up-to-date and dynamic look to content. The website aims to add knowledge, skills, and qualifications to the professional bakery and pastry masters.</p>
                </div>
                <div className="col-md-6">
                    <img src={pakmayaDijitalForm} />
                </div>
            </div>

        </div>
        <div className="fullwidth-image gray referances-detail">
            <div style={{ display: 'flex', alignItems: 'center' }} className="container">
                <div className="col-md-6"><img src={profesyonellereOzel} /></div>
                <div className="col-md-6">
                    <div className="content">
                        <p className="small">Success Story</p>
                        <h2>For Professionals<br/> Content Strategy</h2>
                        <p>On its website, Pakmaya offers special information for professionals with detailed information about the bakery and pastry products, their usage and areas, recipes with video and photo, new bread and cake making trends, new product introductions, free demo service. </p>
                        <p className="small">Over one million targeted users have visited their website since the inauguration of the professional digital communications platform. </p>
                    </div>
                </div>
            </div>
        </div>

        <div className="container">

            <div className="text-center content py60">
                <span style={{ letterSpacing: 1.2 }}>A BRAND NEW DIGITAL TRAINING PLATFORM</span>
                <h2 style={{ marginTop: 7 }}>Pakmaya Professionals Academy</h2>
                <p style={{ maxWidth: 705, margin: '10px auto 0' }}>We have completed the construction and development of the Free Online Education Platform. This platform offers infrastructure developments for business owners, chefs, bakery and pastry masters, suppliers, and all professionals working in the bakery and pastry sector.</p>
            </div>

            <div className="content-section content row" id="gidaGvnlgi">
                <div className="col-md-6">
                    <span className="small">Success Story</span>
                    <h3>Food Safety Education</h3>
                    <p style={{ maxWidth: 450 }}>In cooperation with Özyeğin University, the Food Safety Education set was launched as the first element in the online curriculum. The platform has 4 training modules and 10 courses in Beginner Level, Basic Level, Intermediate and Advanced Level. At the end of this training process, a personal digital training certificate is issued to all of the participants.</p>
                </div>
                <div className="col-md-6">
                    <img src={pakmayaGidaGuvenligi} />
                </div>
            </div>
        </div>

        <div className="row py60 certification" style={{ display: 'flex' }}>
            <div className="col-md-7">
                <img style={{ width: '100%' }} src={pakmayaGidaSertifikasi} />
            </div>
            <div className="col-md-5 content" style={{ alignSelf: 'center', transform: 'translateX(-20%)' }}>
                <div style={{ maxWidth: 430 }}>
                    <h2>Food Certificate</h2>
                    <p>In the programs we started in November 2019, training such as PD pastry, baker, and gastronomy students are given. These trainings continue to certify thousands of food-related professionals by Özyeğin University and Pakmaya.</p>
                </div>
            </div>
        </div>
        <div className="container">
            <div className="text-center content">
                <span className="small">Success Story</span>
                <h2 style={{ marginTop: 7 }}>Pakmaya Global Marketing</h2>
                <p style={{ maxWidth: 705, margin: '10px auto 0' }}>With the Pak Export team, we have been working intensively on export channels to increase the overseas visibility of the brand from 2018. We manage digital advertising campaigns during the food or industry fair periods with website improvements, content edits, and enhancements.</p>
                <a className="svgLink" style={{ marginTop: 20, justifyContent: 'center' }} target="_blank" href="https://pakmayaprofesyonellerdunyasi.com"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 48 48">
                    <g id="Group_11" data-name="Group 11" transform="translate(-432 -2296)">
                        <g id="Ellipse_1" data-name="Ellipse 1" transform="translate(432 2296)" fill="none" stroke="#000" stroke-width="3">
                            <circle cx="24" cy="24" r="24" stroke="none" />
                            <circle cx="24" cy="24" r="22.5" fill="none" />
                        </g>
                        <path id="Path_80" data-name="Path 80" d="M422.384,556.893l-2.14,2.154,7.736,7.846-7.736,7.846,2.14,2.154,9.86-10Z" transform="translate(30.756 1753.107)" />
                    </g>
                </svg>
                    <span>pakmayaprofesyonellerdunyasi.com</span></a>
                <img style={{ maxWidth: 600 }} src={pakmayaGlobalPazarlama} />
            </div>

            <div className="pakmaya-kunye py60">
                <div className="text-center">
                    <span className="small">Success Story</span>
                    <h3>Impressum</h3>
                </div>
                <div className="col-md-6 kunye-table">
                    <p className="kunye-table_head">Impressum of Star of the Kitchen</p>
                    <div>
                        <ul className="col-md-6">
                            <li>Project supervisor</li>
                            <li>Social Media Consultant</li>
                            <li>Interface Design</li>
                            <li>Web Developer</li>
                            <li>Content Marketing</li>
                            <li>SEO, Digital Marketing</li>
                            <li>Social Media Advertising Management</li>
                            <li>Google Ads & Linkedin Ads</li>
                            <li>Visual design</li>
                        </ul>
                        <ul className="col-md-6">
                            <li>Murathan Yıldırım</li>
                            <li>Gizem Altun Bostancı, Merve Gümüş</li>
                            <li>Barış Onay</li>
                            <li>Celal Yeşil</li>
                            <li>Bengü Dinçer</li>
                            <li>Emel Dalabasmaz</li>
                            <li>Gizem Altun Bostancı</li>
                            <li>Şerife Aytekin</li>
                            <li>Sultan Dedeoğlu, Şevval Büyük, Barış Onay</li>
                        </ul>
                    </div>
                </div>
                <div className="col-md-6 kunye-table">
                    <p className="kunye-table_head">Impressum of Pakmaya Professionals World</p>
                    <div>
                        <ul className="col-md-6">
                            <li>Project supervisor</li>
                            <li>Social Media Consultant</li>
                            <li>Interface Design</li>
                            <li>Web Developer</li>
                            <li>Content Marketing</li>
                            <li>SEO, Digital Marketing</li>
                            <li>Social Media Advertising Management</li>
                            <li>Google Ads & Linkedin Ads</li>
                            <li>Visual design</li>
                        </ul>
                        <ul className="col-md-6">
                            <li>Murathan Yıldırım</li>
                            <li>Gizem Altun Bostancı, Merve Gümüş</li>
                            <li>Barış Onay</li>
                            <li>Celal Yeşil</li>
                            <li>Bengü Dinçer</li>
                            <li>Emel Dalabasmaz</li>
                            <li>Gizem Altun Bostancı</li>
                            <li>Şerife Aytekin</li>
                            <li>Sultan Dedeoğlu, Şevval Büyük, Barış Onay</li>
                        </ul>
                    </div>
                </div>
                <div className="col-md-6 kunye-table">
                    <p className="kunye-table_head">Impressum of Professionals Academy</p>
                    <div>
                        <ul className="col-md-6">
                            <li>Project supervisor</li>
                            <li>Social Media Consultant</li>
                            <li>Interface Design</li>
                            <li>Web Developer</li>
                            <li>SEO, Digital Marketing</li>
                            <li>Social Media Advertising Management</li>
                            <li>Google Ads & Linkedin Ads</li>
                            <li>Visual design</li>
                        </ul>
                        <ul className="col-md-6">
                            <li>Murathan Yıldırım</li>
                            <li>Gizem Altun Bostancı, Merve Gümüş</li>
                            <li>Barış Onay</li>
                            <li>Celal Yeşil</li>
                            <li>Emel Dalabasmaz</li>
                            <li>Gizem Altun Bostancı</li>
                            <li>Şerife Aytekin</li>
                            <li>Sultan Dedeoğlu, Şevval Büyük, Barış Onay</li>
                        </ul>
                    </div>
                </div>
                <div className="col-md-6 kunye-table">
                    <p className="kunye-table_head">Impressum of Pakmaya Global Marketing</p>
                    <div>
                        <ul className="col-md-6">
                            <li>Project supervisor</li>
                            <li>Interface Design</li>
                            <li>Content Marketing</li>
                            <li>Web Developer</li>
                        </ul>
                        <ul className="col-md-6">
                            <li>Simay Akkurt</li>
                            <li>Barış Onay</li>
                            <li>Bengü Dinçer</li>
                            <li>Barış Arslan</li>
                        </ul>
                    </div>
                </div>
            </div>

        </div>

        <OtherCaseStudy />

        <Checkup />
    </Layout >
)

export default ReferancesDetail
